.SearchBar {
    background-color: transparent;
    padding-right: 45px;
    margin-bottom: 15px;
    display: flex;
}

.SearchInput {
    width: 90%;
    border-radius: 5px;
}

@media only screen and (max-width: 600px) {
    .SearchInput {
        width: 80%;
        border-radius: 5px;
    }
}