.Header {
    background-color: #86cecb;
    max-width: 100%;
    height: 80px;
    margin: 1%;
    text-align: left;
    display: flex;
}

.Header-nav-container {
    margin: 55px 0 0 10%;
    padding: 0;
    display: flex;
}

.Header-nav {
    margin: 0 0 0 5%;
    min-width: 40%;
    text-wrap: nowrap;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-decoration: underline;
}

.Header-nav-external {
    text-wrap: nowrap;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-decoration: underline;
}

.Header-nav:hover {
    color: #137a7f;
    text-decoration: underline;
}

.Header-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.Header-title {
    font-size: x-large;
    font-weight: bold;
    display: inline-flex;
}

.logo {
    max-width: 290px;
    max-height: 55px;
    width: auto;
    height: auto;
    margin: 10px 0 0 45px;
    display: inline-flex;
}

.spotify-logo {
    max-width: 150px;
    max-height: 35px;
    width: auto;
    height: auto;
    margin: 0 0 10px 20px;
    padding: 0;
    display: inline-flex;
}

@media only screen and (max-width: 600px) {
    .Header {
        height: 30%;
        margin: 0 0 1% 0;
    }

    .Header-nav-container {
        margin-top: 2%;
        margin-right: 10%;
        display: block;
    }

    .Header-nav {
        display: block;
        text-align: left;
    }

    .Header-button {
        text-align: left;
    }

    .logo {
        display: block;
    }
}