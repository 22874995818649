.Vote {
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 17px;
}

.Vote-image-container {
    width: 50px;
    height: 50px;
    overflow: visible;
    margin: 7px;
}

.Vote-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border: none;
}

.Vote-info-container {
    padding: 20px 0 0 30px;
    text-align: left !important;
    word-break: break-word;
}

.Vote-button {
    cursor: pointer;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    outline: inherit;
    text-decoration: underline;
    font: inherit;
    font-weight: bold;
    text-align: left !important;
}

.Vote-button:hover {
    color: #137a7f;
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .Vote-info-container {
        padding: 0;
    }
}