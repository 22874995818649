@import url('https://fonts.googleapis.com/css?family=Roboto:regular,bold,italic');

.App {
  text-align: center;
}

html,
body {
  background-color: #e9e9e9;
  color: black;
  max-width: 100%;
  overflow-x: hidden;
}

* {
  font-family: 'Tahoma Light', Arial, Helvetica, sans-serif !important;
}

.empty {
  width: 100%;
  text-align: center;
}

.buffer {
  height: 200px;
}

.legal {
  position: fixed;
  width: 100%;
  text-align: center;
  bottom: 1%;
  margin: 2% 0% 0% 0%;
  font-size: small;
  color: grey;

  a {
    color: grey;
  }
}

.show-more-container {
  width: 90%;
  margin-top: 20px;
  text-align: center;
  justify-content: center;
}

.show-more-container-left {
  width: 90%;
  margin-top: 20px;
}

.show-more-disabled {
  display: inline-block;
  font-size: medium;
  cursor: default;
  background-color: transparent;
  border: none;
  outline: inherit;
  border-radius: 5px;
  font-weight: bold;
}

.show-more {
  display: inline-block;
  font-size: medium;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: inherit;
  border-radius: 5px;
  text-decoration: underline;
}

.show-more:hover {
  color: #137a7f;
}

@media only screen and (max-width: 600px) {
    .legal {
      text-align: right;
      font-size: xx-small;
      right: 2%;
    }
}