.AddSong {
    position: relative;
    display: flex;
    font-size: medium;
    color: black;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: inherit;
    border-radius: 5px;
    height: auto;
    text-align: left;
    max-width: 100vw;
}

.AddSong:hover:not(.AddSong-added, .AddSong-loading) {
    text-decoration: underline;
    color: #137a7f;
}

.AddSong-added {
    text-decoration: none;
    color: #e12885;
}

.AddSong-icon {
    font-size: 36px;
    margin: 0 5px 0 5px;
}