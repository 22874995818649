.Playlist {
    text-align: center;
    width: 55%;
    margin: 5px 10% 0 40%;
    height: auto;
    padding: 5px;
}

.Playlist-options {
    text-align: left;
    margin-bottom: 10px;
}

.Playlist-option-button {
    justify-content: center;
    align-items: center;
    font-size: medium;
    cursor: pointer;
    margin: 5px;
    outline: inherit;
    border: none;
    background: none;
    text-decoration: none;
}

.Playlist-option-button:hover {
    text-decoration: underline;
    color: #137a7f;
}

.Playlist-option-button:disabled {
    text-decoration: underline;
    color: gray;
    text-decoration-color: #e12885;
    text-decoration-thickness: 4px;
    text-decoration-skip-ink: none;
    cursor: default;
}

.Playlist-bottom-container {
    text-align: center;
    width: 100%;
    margin: 0 50% 0 70%;
}

.Playlist-show-more {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: medium;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: inherit;
    border-radius: 5px;
}

.Playlist-show-more:hover {
    color: #137a7f;
    text-decoration: underline;
}

.Playlist-top {
    display: flex;
    margin-bottom: 10px;
}

.Playlist-stat {
    text-align: left;
    color: black;
    font-size: large;
}

.Playlist-button {
    text-align: left;
    font-size: large;
    margin-left: 15px;
    background: none;
    color: black;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    text-decoration: underline;
}

.Playlist-button:hover {
    color: #137a7f;
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .Playlist {
        margin: 5px;
        width: 95%;
    }

    .Playlist-bottom-container {
        margin: 0 50% 0 50%;
        width: 100%;
    }
}