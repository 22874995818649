.Home {
    width: 100%;
}

.Home-message {
    padding-left: 10px;
}

.Home-sidebar {
    width: 20%;
    float: right;
    background: #bec8d18e;
    border-radius: 5px;
    margin: 2em 5% 200px 0;
    padding: 1%;
}

.Home-button {
    font-family: inherit;
    font-size: medium;
    cursor: pointer;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    outline: inherit;
    text-decoration: underline;
    color: black;
    margin: 0 0 5% 0;
}

.Home-collapsable-button {
    display: block;
    font-family: inherit;
    font-size: medium;
    cursor: pointer;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    outline: inherit;
}

.Home-button:hover {
    text-decoration: underline;
    color: #137a7f;
}

.Home-characterModule {
    display: inline-block;
    width: 150px;
    height: auto;
    padding: 10px;
    outline: none;
    border-color: aliceblue
}

.Home-container {
    justify-content: center;
    width: 70%;
    padding: 0 5% 0 5%;
}

.Filter-list {
    list-style: none;
    padding-left: 0;
    padding-right: 1em;
}

.Filter-list > li:before {
    margin-right: 1em;
}

.Filter-radio {
    font-family: inherit;
    font-size: medium;
    cursor: pointer;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    outline: inherit;
    text-decoration: none;
    color: black;
}

.Filter-radio:hover {
    text-decoration: underline;
    color: #137a7f;
}

.Filter-radio > label {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {

    .Home-container {
        width: 100%;
    }

    .Home-sidebar {
        float: none;
        width: 80%;
        margin: 5%;
        min-height: 0;
    }
}
