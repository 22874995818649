.Profile {
    width: 100%;
}

.Profile-characterModule {
    display: inline-block;
    width: 150px;
    height: auto;
    padding: 10px;
    outline: none;
    border-color: aliceblue
}

.Profile-container {
    justify-content: center;
    width: 90%;
    padding: 0 5% 0 5%;
}

.Profile-button {
    text-align: left;
    font: inherit;
    background: none;
    color: black;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    text-decoration: underline;
}

.Profile-button:hover {
    color: #137a7f;
    text-decoration: underline;
}