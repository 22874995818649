.Song {
    outline: none;
    display: flex;
    color: inherit;
    text-decoration: none;
}

.Song-info {
    margin: 0 20px 0 0;
    text-align: center;
    min-width: 40px;
}

.Song-vote {
    cursor: pointer;
    background: none;
    color: inherit;
    border: none;
    padding: auto;
    outline: inherit;
    font-size: 36px;
    font-weight: bolder;
}

.Song-index {
    font-size: 36px;
    font-weight: bolder;
    width: 100%;
    white-space: nowrap;
}

.Song-vote-voted {
    cursor: pointer;
    background: none;
    color: #e12885;
    border: none;
    padding: 0;
    outline: inherit;
    font-size: 36px;
    font-weight: bolder;
}

.Song-number {
    font-size: 14px;
    margin: 10px 0 0 0;
    width: 100%;
    white-space: nowrap;
}

.Song-track-container {
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 17px;
}

.Song-cover {
    position: relative;
    object-fit: cover;
    width: 100px;
    height: 100px;
    display: inline-block;
    margin: 20px;
}

.Song-side {
    position: relative;
    display: inline-block;
    text-align: left;
    margin: 20px 10px 10px 0;
    width: 100%;
}

.Song-title {
    display: inline-block;
    text-align: left;
    font-size: 13pt;
    font-weight: bold;
    text-overflow: ellipsis;
    color: black;
    text-decoration: underline;
}

.Song-title:hover {
    color: #137a7f;
    text-decoration: underline;
}

.Song-artists {
    display: block;
    text-align: left;
    font-size: 11pt;
    font-weight: bold;
    text-overflow: ellipsis;
    color: #a7a7a7;
    font-weight: 600;
}

.Song-button {
    display: flex;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
}

.Song-explicit {
    display: inline;
    text-decoration: none;
    color: black;
}

.spotify-attrib {
    position: inline-block;
    bottom: 0;
    font-size: 13pt;
    color: black;
    display: flex;
}

.spotify-attrib:hover {
    color: #137a7f;
    text-decoration: underline;
}

.spotify-icon {
    margin-right: 10px;
    width: 13pt;
    height: 13pt;
}

.external-icon {
    margin-left: 4pt;
}