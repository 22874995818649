.WebPlayback-player {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 120px;
    background: rgb(25,20,20);
    color: white;
    /* box-shadow: 0 -1px 10px 1px rgb(25, 20, 20); */
    text-wrap: nowrap;
}

.WebPlayback-player a {
    color: white !important;
    text-decoration: underline;
}

.WebPlayback-cover {
    position: relative;
    object-fit: cover;
    width: 100px;
    height: 100px;
    display: inline-block;
    margin: 10px 0 0 20px;
}

.WebPlayback-side {
    position: absolute;
    display: inline-block;
    margin: 20px 0 0 20px;
    width: 80%;
}

.WebPlayback-title {
    font-size: 13pt;
    text-overflow: ellipsis;
}

.WebPlayback-artists {
    font-size: 11pt;
    text-overflow: ellipsis;
    color: #a7a7a7;
    font-weight: 600;
}

.WebPlayback-controls {
    position: absolute;
}

.WebPlayback-button {
    cursor: pointer;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    outline: inherit;
    color: white;
    font-size: 20pt;
}

.WebPlayback-button-icon {
    width: 15px;
    margin: 6px;
    color: white;
    font-size: 20pt;
}

.button-active {
    color: #1DB954;
}

.WebPlayback-spotify {
    max-height: 100px;
    max-width: 200px;
    font-size: small;
    position: absolute;
    right: 0;
    bottom: 20%;
    color: white;
}

.WebPlayback-spotifyLogo {
    display: block;
    width: 60%;
    height: 60%;
    margin-top: 10%;
    background-color: #191414;
}

@media only screen and (max-width: 600px) {
    .WebPlayback-spotify {
        display: none;
    }
}