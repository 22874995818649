.Character-info {
    position: fixed;
    width: 25%;
    left: 0;
    margin-top: 20px;
    margin-left: 60px;
}

.Character-image {
    object-fit: cover;
    width: 200px;
    height: 200px;
    display: inline-block;
    position: relative;
    object-fit: cover;
    margin: 1%;
}

.Character-side {
    font-size: x-large;
    position: absolute;
    display: inline-block;
    width: 80%;
    padding: 5px 0 5px 5px;
}

.Character-name {
    font-weight: bold;
    word-break: break-word;
}

.Character-media {
    word-break: break-word;
}

.Character-stats {
    margin: 2px 0 2px 0;
    width: 100%;
}

.Character-stats-similar-container {
    margin: 20px 0 0 0;
}

.Character-stats-similar {
    display: flex;
    vertical-align: top;
}

.Character-media-button {
    display: block;
    justify-content: center;
    align-items: center;
    font-size: medium;
    cursor: pointer;
    margin: 5px;
    outline: inherit;
    border: none;
    background: none;
    text-decoration: none;
}

.Character-media-button:hover {
    text-decoration: underline;
    color: #137a7f;
}

@media only screen and (max-width: 600px) {
    .Character-image {
        display: block;
    }

    .Character-side {
        position: relative;
        display: block;
        margin: 1%;
        padding: 5px 0 5px 0;
    }

    .Character-info {
        position: relative;
        width: 100%;
    }
}